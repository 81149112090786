<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        Period and Volume Discounts
        <v-spacer></v-spacer>
        <div class="pa-2" v-if="this.PickedPriceList">
          <input type="file" @change="onFileChange" />
          <v-btn small dark color="success" @click="uploadFile">Upload </v-btn>
          <div v-if="message">{{ message }}</div>
        </div>
        <div v-if="this.PickedPriceList">
          <v-btn
            color="primary"
            small
            dark
            :to="`/inventory/period-discounts/${this.PickedPriceList}/item`"
          >
            Add Item
          </v-btn>
        </div>
      </v-toolbar>
      <v-dialog v-model="loader" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              v-model="PickedPriceList"
              :items="priceList"
              item-text="ListName"
              item-value="id"
              label="Price List"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-btn class="primary" small @click="methodDispatcher()"
              >Filter</v-btn
            >
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="8">
            <v-text-field dense v-model="search" label="Search"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn icon class="ml-10" right>
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" sm="12" md="12">
          <v-data-table
            dense
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="OSPPItem"
            :search="search"
            :items-per-page="options.itemsPerPage"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            :server-search="true"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100, 200],
              showCurrentPage: true,
            }"
            small
          >
            <template v-slot:item.id="{ item }">
              <v-btn
                color="accent"
                text
                :to="`/inventory/period-discounts/${item.ItemCode}/${item.ListNum}/${item.id}`"
                ><v-icon color="success">mdi-plus-circle</v-icon></v-btn
              >
              <v-btn :to="`/period-and-vol-discount/${item.id}`" icon>
                <v-icon color="black">mdi-pencil-circle-outline</v-icon>
              </v-btn>
              <v-btn @click="removeItem(item.id)" color="white" plain small>
                <v-icon color="red"> mdi-delete-circle-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-card-text>
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    file: null,
    message: "",
    search: "",
    searchInput: "",
    selectionType: "leaf",
    trueValue: true,
    regionDialog: false,
    priceList: [],
    record: {},
    Items: [],
    OSPPItem: [],
    Locations: [],
    territories: [],
    BusinessPartners: [],
    ospp: {},
    SrcPrc: [
      { value: 0, text: "Price - Pri. Curr." },
      { value: 1, text: "Price - Add. Curr. 1" },
      { value: 3, text: "Price - Add. Curr. 2" },
    ],
    dialog: false,
    PickedPriceList: null,
    headers: [
      { text: "Item No.", value: "ItemCode" },
      { text: "Item Name", value: "oitm.ItemName" },
      { text: "Primary Currency", value: "Currency" },
      // { text: "Source Price", value: "src_price" },
      // { text: "Price Unit", value: "Price" },
      { text: "Valid From", value: "ValidFrom" },
      { text: "Valid To", value: "ValidTo" },
      { text: "CardCode", value: "CardCode" },
      { text: "Action", value: "id" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 50, 100],
      itemsPerPageOptions: [10, 20, 50, 100],
      itemsPerPage: 100,
      page: 1,
    },
    totalItems: 0,
  }),
  watch: {
    // "ospp.ItemCode": "getPriceListItems",

    options: {
      handler() {
        if (this.PickedPriceList) {
          this.getItems();
        }
      },
      deep: true,
    },
    search() {
      this.page = 1;
      if (this.search.length > 2) {
        this.getItems();
      }
      if (this.search.length == 0) {
        this.getItems();
      }
    },
  },
  methods: {
    methodDispatcher() {
      this.getItems();
      this.getPriceListItems();
    },
    getPriceList() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/price_lists`)
        .then((res) => {
          self.priceList = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getPriceListItems() {
      const self = this;
      this.loading = true;
      let url = `/price_lists/${this.PickedPriceList}/items`;

      if (this.ospp.ItemCode && this.ospp.ItemCode != undefined) {
        url += `?ItemCode=${this.ospp.ItemCode}`;
      }

      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.Items = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getItems() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch(
          "get",
          `period_discount/${this.PickedPriceList}?page=${this.options.page}&per_page=${this.options.itemsPerPage}&search=${this.search}`
        )

        .then((res) => {
          self.OSPPItem = res.ResponseData.data;
          self.options.page = res.ResponseData.current_page;
          self.options.itemsPerPage = res.ResponseData.per_page;
          self.totalItems = res.ResponseData.total;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    getLocations() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `locations`)
        .then((res) => {
          self.Locations = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    SaveItemDiscnt() {
      const url = `/period-and-vol-discounts`;
      const self = this;
      this.ospp.ListNum = this.PickedPriceList;
      const data = this.ospp;
      self.$store
        .dispatch("post", { url, data })
        .then(() => {
          this.dialog = false;
          this.ospp = {};
          this.getItems();
        })
        .catch((err) => {
          console.log(err, "err");
          this.dialog = false;
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBpartners() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.BusinessPartners = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    selectRegion(data) {
      this.territory = data;
      console.log(data);
      this.ospp.LctCode = data.id;
      this.regionDialog = false;
    },
    getTerritories() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/territories`)
        .then((res) => {
          self.territories = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    openRegionModal() {
      this.regionDialog = true;
    },
    removeItem(id) {
      var userResponse = confirm(
        "Are you sure you want to delete? This action cannot be undone."
      );
      if (userResponse === true) {
        const url = `/period-and-vol-discounts/${id}`;
        const self = this;
        self.$store
          .dispatch("remove", url)
          .then(() => {
            this.getItems();
          })
          .catch((err) => {
            console.log(err, "err");

            this.$refs.snackbar.show(err, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },

    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      if (!this.file) {
        this.message = "Please select a file";
        return;
      }

      let formData = new FormData();
      formData.append("file", this.file);
      this.loading = true;

      try {
        let url = "import-promotional-items";
        let response = await this.$store.dispatch("post", {
          url,
          data: formData,
        });
        console.log(response.ResponseData);
        this.loading = false;
        this.$refs.snackbar.show(response.ResponseData, "success");
      } catch (err) {
        this.$refs.snackbar.show(err, "red");
        this.loading = false;
      }
    },
  },
  created() {
    this.getLocations();
    this.getBpartners();
    this.getPriceList();
    this.getTerritories();
  },
};
</script>
